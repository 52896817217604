import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export default function WebPageSchema({ pageMeta }) {
  const { title, description } = pageMeta;

  const schema = {
    '@context': 'http://schema.org/',
    '@graph': [
      {
        '@type': 'WebPage',
        name: title,
        description,
      },
    ],
  };
  const schemaString = JSON.stringify(schema);

  return (
    <Helmet>
      <script type="application/ld+json">{schemaString}</script>
    </Helmet>
  );
}

WebPageSchema.propTypes = {
  pageMeta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import VisuallyHidden from '../VisuallyHidden';

import * as BlogArchivePostsStyles from './blog-archive-posts.module.scss';

function Post({ post }) {
  const { title, publishedDate, prettyPublishedDate } = post.frontmatter;

  return (
    <article className={BlogArchivePostsStyles.post}>
      <span aria-hidden="true">&gt;</span>
      <Link to={`/blog/${post.fields.slug}/`}>
        <h2>{title}</h2>
        <p>
          <VisuallyHidden>Published: </VisuallyHidden>
          <time dateTime={publishedDate}>{prettyPublishedDate}</time>
        </p>
      </Link>
    </article>
  );
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
};

// assumes post is in order by date
export default function Posts({ posts }) {
  return (
    <>
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
    </>
  );
}

Posts.propTypes = {
  posts: PropTypes.array.isRequired,
};

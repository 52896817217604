import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Emoji from 'a11y-react-emoji';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import WebPageSchema from '../../components/WebPageSchema';
import BlogArchivePosts from '../../components/BlogArchivePosts';

export default function BlogArchivePage({ data }) {
  const posts = data.allMdx.nodes;
  const pageMeta = {
    title: 'Blog',
    description: 'Blog archive of Andy Nguyen, a web developer.',
    uri: 'blog',
  };

  return (
    <Layout>
      <Seo pageMeta={pageMeta} />
      <WebPageSchema pageMeta={pageMeta} />
      <section aria-labelledby="blog">
        <h1 id="blog">
          Blog <Emoji symbol="📝" />
        </h1>
      </section>
      <BlogArchivePosts posts={posts} />
    </Layout>
  );
}

BlogArchivePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query BlogArchivePageQuery {
    allMdx(
      sort: { fields: frontmatter___publishedDate, order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          title
          publishedDate
          prettyPublishedDate: publishedDate(formatString: "MMM DD, YYYY")
        }
        id
        fields {
          slug
        }
      }
    }
  }
`;
